import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import lfApi from "../api/lfApi";
import { getBillingPortalUrl } from "../api/lfApiSubscription";

function Settings({ userProfile, logout }) {
    const [billingPortalUrl, setBillingPortalUrl] = useState(null);

    useEffect(() => {
        getBillingPortalUrl().then(setBillingPortalUrl);
    }, []);

    const navigate = useNavigate();
    
    const onLogout = async () => {
        await lfApi.post('/auth/logout', { redirect: false });

        logout();
        navigate('/');
    };

    return <div className="w-full text-left">
        <div className="flex w-full justify-end">
            <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2" 
                    onClick={onLogout}>
                Logout
            </button>
        </div>

        <Routes>
            <Route path="" element={
                <div>
                    <h1 className="text-xl font-semibold">Profile</h1>

                    <table className="">
                        <tbody>
                            <tr>
                                <td className="p-1 pl-0">Display Name:</td>
                                <td className="p-1">{userProfile.name}</td>
                            </tr>
                            <tr>
                                <td className="p-1 pl-0">Email:</td>
                                <td className="p-1">{userProfile.email}</td>
                            </tr>
                        </tbody>
                    </table>

                    {
                        billingPortalUrl && (
                            <div>
                                <h1 className="text-xl font-semibold mt-6 mb-4">Subscription</h1>

                                <a href={billingPortalUrl} 
                                    target="_blank" rel="noopener noreferrer"
                                    className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer">
                                    Manage subscription
                                </a>
                            </div>
                        )
                    }
                </div>
            } />
        </Routes>
    </div>;
};

export default Settings;
