import React from 'react';

const ToggleButton = ({isToggled, setIsToggled}) => {
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className="flex items-center justify-center">
      <button
        onClick={handleToggle}
        className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none ${
          isToggled ? 'bg-blue-500' : 'bg-gray-300'
        }`}
      >
        <span
          className={`${
            isToggled ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
        />
      </button>
    </div>
  );
};

export default ToggleButton;
