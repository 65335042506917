import ReactGA from 'react-ga4';

const initializeAnalytics = () => {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID || 'G-QBTFWYNDL1');
};

const setUserId = (userId) => {
    ReactGA.set({ userId });
};

const setUserProperties = (properties) => {
    ReactGA.set(properties);
};

const logPageView = (path) => {
    ReactGA.send({ hitType: 'pageview', page: path });
};

const logEvent = ({category, action, label, props}) => {
    console.log('Logging event:', {category, action, label});
    ReactGA.event({
        category,
        action,
        label,
        ...props,
    });
};

export { initializeAnalytics, setUserId, setUserProperties, logPageView, logEvent };
