import React, { useEffect, useState } from 'react';
import lfApi from '../api/lfApi';
import { donutChart } from '../api/donutChart';
import Calendar from './Calendar';

const RecentActivities = ({ username }) => {
    const [history, setHistory] = useState({ daily_histories: [] });
    const [visibleVideos, setVisibleVideos] = useState([]);

    const getHistory = async () => {
        const response = await lfApi.post(
            'history/',
            {
                username: username,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                days: 7,
            },
        );
        if (response.status === 200) {
            setHistory(response.data);
        }
        else {
            console.error('Failed to fetch history');
        }
    };

    const onVisibleVideo = (videoId) => {
        setVisibleVideos([...visibleVideos, videoId]);
    };

    useEffect(() => {
        getHistory();
    }, []); // The empty array means this effect runs once on mount

    return <div>
    {
        history.daily_histories.map((dailyHistory, historyIndex) => (
            <div key={historyIndex}>
                <div className="my-4">
                    <Calendar
                        dayOfWeek={dailyHistory.date.day_of_week}
                        day={dailyHistory.date.day}
                        monthYear={`${dailyHistory.date.month_str} ${dailyHistory.date.year}`} />
                </div>

                {
                    dailyHistory.study_plans.map((studyPlan, studyPlanIndex) => (
                        <div key={studyPlanIndex}>
                        {
                            studyPlan.lessons.map((lesson, lessonIndex) => (
                                <div key={lessonIndex}>
                                    <div className="font-bold">
                                        {studyPlan.subject}: {lesson.title}
                                    </div>

                                    {
                                        lesson.videos.map((video, videoIndex) => (
                                            <div key={videoIndex} className="flex border rounded-2xl p-3 my-2">
                                                {/* Thumbnail */}
                                                <div className="relative aspect-video rounded-xl overflow-hidden flex items-center justify-center bg-black w-1/2">
                                                    <iframe
                                                        title="video player"
                                                        src={`https://www.youtube.com/embed/${video.video_id}?rel=0&autoplay=0`}
                                                        className={`w-full aspect-video rounded-xl ${visibleVideos.includes(video.video_id) ? '' : 'hidden'}`}
                                                        allowFullScreen
                                                    ></iframe>
                                                    <img
                                                        src={video.thumbnail}
                                                        alt={video.title}
                                                        className={`w-full object-fill cursor-pointer ${visibleVideos.includes(video.video_id) ? 'hidden' : ''}`}
                                                        onClick={() => onVisibleVideo(video.video_id)}
                                                    />
                                                </div>

                                                {/* Title */}
                                                <div className="flex-1 ml-4">
                                                    <h4 className="font-bold">
                                                        {video.title}
                                                    </h4>
                                                    <p>{video.channel_name}</p>

                                                    <div className="flex items-center">
                                                        {donutChart({ percentage: video.data.progress })}
                                                        <div className="ml-1">{video.data.progress}% completed</div>
                                                    </div>

                                                    {
                                                        video.data.summaries.length > 0 &&
                                                        <div className="mt-6 text-sm">
                                                            <div className="font-semibold">Lessons:</div>
                                                            <ul className="list-disc list-outside pl-6">
                                                                {
                                                                    video.data.summaries.map((summary, summaryIndex) => (
                                                                        <li key={summaryIndex}>
                                                                            <span className="font-semibold">{summary.title}</span>: {summary.explanation}
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                        </div>
                    ))
                }
            </div>
        ))
    }
    </div>;
};

export default RecentActivities;
