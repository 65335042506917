
const StudyLesson = ({
        lesson, 
        index, 
        isLastLesson, 
        children, 
        lessonTitleStyle = "font-semibold",
        lessonExplanationStyle = "",
    }) => {
    return <div key={index} className="flex items-start mb-4">
        <div className="mr-4 flex flex-col items-center">
            <div className="w-12 h-12 bg-white border border-black flex items-center justify-center rounded-md">
                {index + 1}
            </div>
            {
                !isLastLesson && <div className="w-12 text-center border-t-black">
                    ⇣
                </div>
            }
        </div>
        <div>
            <div className={lessonTitleStyle}>{lesson.title}</div>
            <div className={lessonExplanationStyle}>{lesson.explanation}</div>
            {children}
        </div>
    </div>
};

export default StudyLesson;
