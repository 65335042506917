import React, { useState } from 'react';
import lfApi from '../api/lfApi';
import LoadingButton from '../components/LoadingButton';

function LoginPage({ fetchUser }) {
    const passwordLogin = 'password';
    const tokenLogin = 'token';

    const [parentCred, setParentCred] = useState(
        { type: 'user', username: '', password: '', token: '' });
    const [loginType, setLoginType] = useState(passwordLogin);
    const [isParentLoggingIn, setIsParentLoggingIn] = useState(false);

    async function _handleLogin(event, loginType) {
        if (event)
            event.preventDefault();

        if (loginType === passwordLogin && (!parentCred.username || !parentCred.password)) {
            return;
        }
        if (loginType === tokenLogin && !parentCred.token) {
            return;
        }

        setIsParentLoggingIn(true);

        try {
            const loginUrl = loginType === passwordLogin ? '/auth/login_cred' : '/auth/login_token';
            const response = await lfApi.post(loginUrl, parentCred);
            if (response.status === 200) {
                if (loginType === passwordLogin) {
                    setLoginType(tokenLogin);
                } else {
                    await fetchUser();
                }
            }
            else {
                console.log('Login failed');
            }
        }
        catch (error) {
            console.error('Login failed: ', error);
        }

        setIsParentLoggingIn(false);
    };

    async function _handlePasswordLogin(event) {
        return await _handleLogin(event, passwordLogin);
    }
    
    async function handleParentLogin(event) {
        return await _handleLogin(event, loginType);
    }

    function handleParentChange(e) {
        const { name, value } = e.target;
        setParentCred(prevState => ({ ...prevState, [name]: value }));
    };

    return <div className="flex mx-auto max-w-screen-lg my-8">
        <div className="inline-flex flex-col items-center border border-gray-300 rounded-2xl mx-auto py-6 px-20">
            <h2 className="text-lg mb-6">Parent Login</h2>
            <form onSubmit={handleParentLogin} className="flex flex-col items-center">
                {
                    loginType === passwordLogin && <input
                        type="email"
                        className="border border-gray-300 p-1 my-1"
                        name="username"
                        placeholder="Email"
                        value={parentCred.username}
                        onChange={handleParentChange} />
                }
                {
                    loginType === passwordLogin && <input
                        type="password"
                        className="border border-gray-300 p-1 my-1"
                        name="password"
                        placeholder="Password"
                        value={parentCred.password}
                        onChange={handleParentChange} />
                }
                {
                    loginType === tokenLogin && <div className="text-sm mb-5">
                        <div>Verification code sent to {parentCred.username}.</div>
                        <div>Check the spam folder if you haven't received it, 
                            or <span className="text-blue-500 underline cursor-pointer" onClick={_handlePasswordLogin}>resend</span>.
                        </div>
                    </div>
                }
                {
                    loginType === tokenLogin && <input
                        type="text"
                        className="border border-gray-300 p-1 my-1"
                        name="token"
                        pattern="\d{6}" 
                        maxLength="6" 
                        inputMode="numeric"
                        placeholder="Verification code"
                        value={parentCred.token}
                        onChange={handleParentChange} />
                }
                
                <LoadingButton
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded my-3"
                    text="Login"
                    isLoading={isParentLoggingIn}
                    onClick={handleParentLogin} />

                <a href="/signup" className="text-blue-500 underline text-sm mt-6">
                    Create an account
                </a>
            </form>
        </div>
    </div>;
}

export default LoginPage;
