const apiUrl = process.env.REACT_APP_LF_API_URL || 'http://localhost:5001';

export function stream_get(path, params) {
  const urlParams = new URLSearchParams(params);
  const baseUrl = apiUrl;
  return fetch(
      `${baseUrl}/${path}?${urlParams.toString()}`,
      {
          credentials: 'include',
      }
  );
}

export function stream_post(path, params) {
  const baseUrl = apiUrl;
  return fetch(
      `${baseUrl}/${path}`,
      {
          method: 'POST',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
      }
  );
}
