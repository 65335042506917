import React from 'react';
import './StudyPlanTopic.css';

const StudyPlanTopic = ({ topic, index }) => {
    return <div className="list-counter-item p-1">
        <div className="circle-number">{index + 1}</div>
        <div className="item-text">{topic.title}</div>
    </div>;
};

export default StudyPlanTopic;
