import React, { useState } from 'react';
import './LoadingButton.css';

const LoadingButton = ({ onClick, text, imgSrc = null, isLoading = false, disabled = false, ...otherProps }) => {
    const [isInternalLoading, setIsInternalLoading] = useState(false);

    function isLoadingNow() {
        return isLoading || isInternalLoading;
    }

    async function handleClick() {
        setIsInternalLoading(true);
        await onClick();
        setIsInternalLoading(false);
    }

    return <div className="flex items-center">
        {
            imgSrc 
                ? <div className="cursor-pointer" onClick={handleClick}>
                    <img src={imgSrc}
                        className="w-8 h-8 p-1 border border-white rounded-lg hover:bg-gray-200"
                        alt={text} />
                </div> : <button 
                    className={`${isLoadingNow() || disabled ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded whitespace-nowrap`} 
                    onClick={handleClick} disabled={isLoadingNow() || disabled}
                    {...otherProps}>
                    {text}
                </button>
        }
        {
            isLoadingNow() && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        }
    </div>;
};

export default LoadingButton;
