import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import lfApi from '../api/lfApi';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutButton = ({text = 'Subscribe', price_type, quantity}) => {
  const handleClick = async () => {
    const stripe = await stripePromise;

    const response = await lfApi.post('/subscription/checkout_session', {
        price_type: price_type,
        quantity: quantity,
    });

    const session = await response.data;

    const result = await stripe.redirectToCheckout({
      sessionId: session.session_id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  return (
    <button role="link" onClick={handleClick} className="bg-blue-500 text-white px-4 py-2 rounded-md">
      {text}
    </button>
  );
};

export default CheckoutButton;
