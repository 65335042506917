export async function processText(res, callback) {
    const reader = res.body.getReader();
    var { done, value } = await reader.read();
    var response = '';
    while (!done) {
        response += new TextDecoder().decode(value);
        callback(response);
        ({ done, value } = await reader.read());
    }
    return response;
}

export function parseLines(text) {
    return text.split(/\n+/).map((item) => item.trim()).filter((item) => item !== '');
}
