import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderContext } from '../contexts/HeaderContext';
import { getProfilePicture } from '../api/profile';

function Header({ userProfile, subscriptionAction, onUserProfile, onChildProfile, onAddChildProfile }) {
    const location = useLocation();
    const { headerData } = useContext(HeaderContext);

    function getUsername() {
        const values = location.pathname.split('/');
        return values[1] === 'profile' ? values[2] : '';
    }

    function getStyle(username) {
        return username === getUsername() ? 'border border-blue-600 border-2' : '';
    }

    function hasSubscriptionAction() {
        return subscriptionAction.action !== 'none';
    }

    return <nav className="bg-sky pt-4">
        <div className="flex justify-between mx-auto max-w-screen-lg">
            {/* Left-aligned content */}
            <div>
            </div>
        
            {/* Right-aligned div */}
            <div className="ml-auto flex flex-col">
                <div className="mr-4 cursor-pointer" onClick={onUserProfile}>
                    { userProfile ? userProfile.name : '' }
                </div>
            </div>
        </div>
        <div className="flex justify-between">
            {/* Left-aligned content */}
            <div className="w-1/3 sm:w-1/4 lg:w-1/5">
                <img src="/bg-tree-top-left-2.png" alt="" className="w-full" />
            </div>

            {/* Center-aligned content */}
            <div className="z-10 w-1/3 sm:w-1/2 lg:w-3/5 flex items-center justify-center">
            {
                getUsername() && <div className="flex justify-center gap-4">
                    {
                    headerData && headerData.map((childProfile) => (
                        <div key={childProfile.id} className="text-center">
                            <div className={`w-24 h-24 rounded-full bg-white flex items-center justify-center mx-auto cursor-pointer ${getStyle(childProfile.username)}`}
                                    onClick={() => onChildProfile(childProfile.username)}>
                                {getProfilePicture(childProfile, '🎾')}
                            </div>
                            <div className="mt-2">
                                {childProfile.first_name}
                            </div>
                        </div>
                    ))
                    }
                    {
                        !hasSubscriptionAction() &&
                        <div className="text-center">
                            <div
                                className="w-24 h-24 rounded-full bg-white flex items-center justify-center mx-auto cursor-pointer"
                                onClick={onAddChildProfile}>
                                ＋
                            </div>
                            <div className="mt-2">Add</div>
                        </div>
                    }
                </div>
            }
            </div>

            {/* Right-aligned div */}
            <div className="ml-auto w-1/3 sm:w-1/4 lg:w-1/5">
                <img src="/bg-tree-top-right-2.png" alt="" className="w-full" />
            </div>
        </div>
    </nav>;    
}

export default Header;
