import React, { useState } from 'react';

const BillingDetail = ({ subscriptionAction }) => {
    const [showBillingDetail, setShowBillingDetail] = useState(false);

    return (
        <>
            {
                !showBillingDetail && <div className="text-blue-500 underline text-sm cursor-pointer" onClick={() => setShowBillingDetail(true)}>
                    See billing detail
                </div>
            }
            {
                showBillingDetail && <table className="mt-2 ml-7">
                    <thead>
                        <tr>
                            <th className="border p-1 px-2">Description</th>
                            <th className="border p-1 px-2 text-right">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            subscriptionAction.additional_charges.details.map(charge => {
                                return <tr key={charge.name}>
                                    <td className="border p-1 px-2">{charge.description}</td>
                                    <td className="border p-1 px-2 text-right">{charge.formatted_amount}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            }
        </>
    );
};

export default BillingDetail;
