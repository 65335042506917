export function donutChart({ 
        percentage, 
        outsideSize = "w-5 h-5", 
        insideSize = "w-3 h-3",
    }) {
    return (
        <div className={`relative my-1 ${outsideSize} rounded-full bg-gray-200`} style={{ '--percentage': `${percentage}%` }}>
            <div className="absolute inset-0 flex items-center justify-center">
                <div className={`${insideSize} bg-white rounded-full`}></div>
            </div>
            <style jsx="true">{`
                .relative {
                    background: conic-gradient(
                        blue var(--percentage), 
                        #a9a9a9 0
                    );
                }
            `}</style>
        </div>
    );
};
