import lfApi from './lfApi';

export async function getSubscriptionAction(doAddChild) {
    try {
        const response = await lfApi.post('/subscription/action', { do_add_child: doAddChild });
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.error('Failed to get subscription action');
        }
    }
    catch (error) {
        console.error('Error getting subscription action:', error);
    }

    return null;
}

export async function setSubscriptionQuantity(quantity) {
    try {
        const response = await lfApi.post('/subscription/quantity', { quantity });
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.error('Failed to set subscription quantity');
        }
    }
    catch (error) {
        console.error('Error setting subscription quantity:', error);
    }

    return null;
}

export async function endTrial() {
    try {
        const response = await lfApi.post('/subscription/end_trial');
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.error('Failed to end trial');
        }
    }
    catch (error) {
        console.error('Error ending trial:', error);
    }

    return null;
}

export function getAdditionalChargeTotal(action) {
    if (['increase_quantity', 'increase_quantity_trial', 'decrease_quantity', 'decrease_quantity_trial'].includes(action.action)) {
        return action.additional_charges.total.formatted_amount;
    }

    return 0;
}

export function getAdditionalSeats(action) {
    if (['increase_quantity', 'increase_quantity_trial'].includes(action.action)) {
        return action.additional_charges.additional_quantity === 1 
            ? '1 seat' 
            : `${action.additional_charges.additional_quantity} seats`;
    }

    return '';
}

export async function getBillingPortalUrl() {
    try {
        const response = await lfApi.post('/subscription/billing_portal');
        if (response.status === 200) {
            return response.data.url;
        }
        else {
            console.error('Failed to get billing portal url');
        }
    }
    catch (error) {
        console.error('Error getting billing portal url:', error);
    }

    return null;
}
