import React from 'react';

const StepIndicator = ({ currentStep, numSteps, onClicks }) => {
  return (
    <div>
        <div className="flex items-center space-x-1 my-4">
        {[...Array(numSteps)].map((_, index) => {
            const step = index + 1;
            const onClick = step < currentStep ? onClicks[index] : null;
            return (
            <React.Fragment key={step}>
                {index !== 0 && (
                <div className={`h-0.5 w-4 ${currentStep > index ? 'bg-blue-500' : 'bg-gray-300'}`} />
                )}
                <div
                className={`w-8 h-8 flex items-center justify-center rounded-full border-2 
                            ${currentStep === step ? 'bg-blue-500 text-white' : 'bg-white text-gray-500'} 
                            ${currentStep >= step ? 'border-blue-500' : 'border-gray-300'}
                            ${step < currentStep ? 'cursor-pointer' : 'cursor-default'}`}
                onClick={onClick}
                >
                {step}
                </div>
            </React.Fragment>
            );
        })}
        </div>
        <div className="text-gray-500 text-sm">
            Step {currentStep}
        </div>
    </div>
  );
};

export default StepIndicator;
